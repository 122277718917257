import { UserRole } from '@/constants';
import apiDef from '@/constants/api';
import { SupportThirdPartyAuthPlatform } from '@/page-components/login/ThirdPartyAuthButtons';
import request from '@/utils/request';
import { tuple } from '@/utils/typeHelpers';

export interface LoginResult {
  userId: number;
  email: string;
  userType: UserRole;
  authorization: string;
  status: number;
}

export async function login(email: string, password: string) {
  return request.post<LoginResult>(apiDef.login, {
    data: { email, password, forAdvertising: true },
  });
}

export async function thirdPartyLogin(info: ThirdPartyRegisterInfo) {
  return request.post<LoginResult>(apiDef.thirdPartyLogin, {
    data: {
      ...info,
      forAdvertising: true,
    },
  });
}

interface IRegisterMcnParam {
  email: string;
  password: string;
}

export const mcnRegister = (payload: IRegisterMcnParam, useMock?: boolean) => {
  return request.post(apiDef.registerMcn, {
    data: {
      ...payload,
      forAdvertising: true,
    },
    useMock,
  });
};

export enum RegisterFromType {
  KOL,
  KOC,
  H5,
  MBOX,
}
export interface KolRegisterInfo {
  email: string;
  password: string; // rsa加密后
  platform?: string;
  thirdPartyCode?: string;
  registerFrom: RegisterFromType;
}

// 新版kol注册
export async function kolRegister(info: KolRegisterInfo) {
  return request.post(apiDef.kolRegister, {
    data: {
      ...info,
      forAdvertising: true,
    },
  });
}

interface RegisterAdvertiserParam {
  password: string;
  email: string;
  acceptRecommend: boolean;
  hl: string;
}
//广告主注册
export function registerAdvertiser({ hl, ...data }: RegisterAdvertiserParam) {
  return request.post(apiDef.registerAdvertiser, {
    data: {
      ...data,
      forAdvertising: true,
    },
    params: { hl },
  });
}

interface ThirdPartyRegisterInfo {
  userId: string; // 需要 rsa 加密
  email: string; // 需要 rsa 加密
}
interface KolThirdPartyRegisterInfo extends ThirdPartyRegisterInfo {
  acceptRecommend: boolean; // 是否接受信息推送
  registerFrom: number; // 注册来源 0-后台 1-KOC投放平台 2-KOC_H5
}

// 第三方授权KOL注册
export async function kolThirdPartyRegister(info: KolThirdPartyRegisterInfo) {
  return request.post<LoginResult>(apiDef.kolThirdPartyRegister, {
    data: {
      ...info,
      forAdvertising: true,
    },
  });
}

// 第三方授权广告主注册
export async function advertiserThirdPartyRegister(info: ThirdPartyRegisterInfo & { acceptRecommend: boolean }) {
  return request.post<LoginResult>(apiDef.advertiserThirdPartyRegister, {
    data: { ...info, forAdvertising: true },
  });
}

interface CheckEmailRegisteredReturn {
  hasRegistered: boolean;
  mcnApplyLink?: string;
}
//检查邮箱是否已存在
export async function checkEmailRegistered(email: string) {
  return request.get<CheckEmailRegisteredReturn>(apiDef.checkEmailRegistered, {
    params: { email },
  });
}

interface AuthorizedPlatforms {
  authorizedPlatforms: string[];
  thirdPartyLoginPlatforms: SupportThirdPartyAuthPlatform;
}

// 获取已授权过审的社媒平台
export function getAuthorizedPlatforms() {
  return request.get<AuthorizedPlatforms>(apiDef.getAuthorizedPlatforms);
}

export const userIdentityTypes = tuple('KOL', 'MCN', 'ADVERTISER');

export type UserIdentity = (typeof userIdentityTypes)[number];
interface SendActiveMailParams {
  email: string;
  hl?: string;
  identity?: UserIdentity | null;
}

export interface CommonResult<T = string> {
  result: T;
}
// 发送激活邮件 // 400001
export async function sendActivateMail(payload: SendActiveMailParams) {
  const { identity } = payload;
  let apiUrl = '';

  switch (identity) {
    case 'MCN':
      apiUrl = apiDef.sendMCNActivateMail;
      break;
    case 'ADVERTISER':
      apiUrl = apiDef.sendAdvertiserActiveEmail;
      break;
    default:
      apiUrl = apiDef.sendActivateMail;
      break;
  }

  if (!apiUrl) return;

  return request.get<CommonResult>(apiUrl, {
    params: {
      ...payload,
      forAdvertising: true,
    },
  });
}

interface ActiveAdvertiserParams {
  activationCode: string;
  email: string;
  hl: string;
}
interface ActiveAdvertiserResult extends LoginResult {
  status: number;
  userId: number;
  userType: number;
  mcnApplyLink: string;
}
//激活广告主账户
export function activeAdvertiser(payload: ActiveAdvertiserParams) {
  return request.get<ActiveAdvertiserResult>(apiDef.activeAdvertiser, {
    params: payload,
  });
}

export interface FillAdvertiserInfoParams {
  companyName: string;
  industry: string;
  industryType: number;
  countryId: number;
  countryName: string;
  scale: number;
  contactName: string;
  phoneNumber?: string;
  phoneCountryId?: number;
}
//完善广告主公司信息
export function fillAdvertiserInfo(payload: FillAdvertiserInfoParams) {
  return request.post(apiDef.fillAdvertiserInfo, {
    data: payload,
  });
}

// 重置密码
export async function validatePasswordResetCode(email: string, resetCode: string) {
  return request.get<CommonResult>(apiDef.validatePasswordResetCode, {
    params: { email, resetCode },
    headers: {
      token: '',
    },
  });
}
//更改密码
export async function changePassword(email: string, password: string, resetCode: string) {
  return request.post(apiDef.changePassword, {
    data: { email, password, resetCode },
  });
}

export interface SendResetMailParams {
  email: string;
  hl: string;
}

export function sendResetMail(payload: SendResetMailParams) {
  return request.get(apiDef.sendResetMail, {
    params: {
      ...payload,
      forAdvertising: true,
    },
  });
}

// 激活账号
/*
  HTTP_400_ABNORNAL_ACTIVATION_CODE(400006, "激活码无法识别"),
  HTTP_400_EXPRIED_ACTIVATION_CODE(400007, "激活码已过期"),
  HTTP_400_USER_ACTIVED(400005, "该账户已激活"),
  HTTP_400_SOCIAL_MEDIA_ALREADY_BIND_ERROR(400022, "社交媒体账号已被绑定"),
  HTTP_400_ACTIVATION_LINK_INVALID_ERROR(400023, "该激活链接已失效"),
*/
export async function activateKol(email: string, activationCode: string) {
  return request.get<LoginResult>(apiDef.activateKol, {
    params: { email, activationCode },
  });
}
